import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/services/auth.guard';
import { NoAuthGuard } from 'src/services/no-auth.guard';
const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
    canActivate: [NoAuthGuard],
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'investor-list',
    loadChildren: () =>
      import('./pages/investor-list/investor-list.module').then(
        (m) => m.InvestorListModule,
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'action-summary',
    loadChildren: () =>
      import('./pages/action-summary/action-summary.module').then(
        (m) => m.ActionSummaryModule,
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'investor-details/:id',
    loadChildren: () =>
      import('./pages/investor-details/investor-details.module').then(
        (m) => m.InvestorDetailsModule,
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'investor-stats',
    loadChildren: () =>
      import('./pages/investor-stats/investor-stats.module').then(
        (m) => m.InvestorStatsModule,
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'smart-saver-stats',
    loadChildren: () =>
      import('./pages/smart-saver-stats/smart-saver-stats.module').then(
        (m) => m.SmartSaverStatsModule,
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'assets',
    loadChildren: () =>
      import('./pages/asset/asset.module').then((m) => m.AssetModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'feedback',
    loadChildren: () =>
      import('./pages/feedback/feedback.module').then((m) => m.FeedbackModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'channel-partner',
    loadChildren: () =>
      import('./pages/channel-partner/channel-partner.module').then(
        (m) => m.ChannelPartnerModule,
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'support-requests',
    loadChildren: () =>
      import('./pages/supports-request/supports-request.module').then(
        (m) => m.SupportsRequestModule,
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'smart-saver-investments',
    loadChildren: () =>
      import(
        './pages/smart-saver-investments/smart-saver-investments.module'
      ).then((m) => m.SmartSaverInvestmentsModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'fd-investments',
    loadChildren: () =>
      import('./pages/fd-investments/fd-investments.module').then(
        (m) => m.FdInvestmentsModule,
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'smart-investments',
    loadChildren: () =>
      import('./pages/smart-investments/smart-investments.module').then(
        (m) => m.SmartInvestmentsModule,
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'withdraw',
    loadChildren: () =>
      import('./pages/withdraw-request/withdraw-request.module').then(
        (m) => m.WithdrawRequestModule,
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'manual-investments',
    loadChildren: () =>
      import('./pages/manual-investments/manual-investments.module').then(
        (m) => m.ManualInvestmentsModule,
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'bond-investments',
    loadChildren: () =>
      import('./pages/yubi-investments/yubi-investments.module').then(
        (m) => m.YubiInvestmentsModule,
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'keywords',
    loadChildren: () =>
      import('./pages/keyword/keyword.module').then((m) => m.KeywordModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
